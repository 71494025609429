/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');

/*.transparent-modal{
    --background: rgba(46, 43, 46, 0.9);
    text-decoration: none;
    
    &::part(content){
        backdrop-filter: blur(12px);
    }
}*/

.titulo{
    font-family: 'Anton', sans-serif;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
   

}

.subtitulo{
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.subtitulobo{
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.success{
    font-family: 'Rubik', sans-serif;
    font-size: 15px ;
    font-weight: bold;
    text-align: center ;
}
.normal{
    font-family: 'Rubik', sans-serif;
    font-size: 12px ;
    text-align: justify ;
}

.img {
    padding: 10px;
    border: 1px solid #e9ecef;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}
.encabezado { 
 border-radius: 30px;
 margin: 20px;
 text-align: center;
 padding: 10px;
}

.img-tour{
    width: 200px;
    margin: auto;
}

